































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import SlotGroup from '@/models/SlotGroup.model';
import { ShiftConfig } from '@/interfaces/ShiftConfig.interface';
import { SlotType } from '@/enums/SlotType.enum';
import { namespace } from 'vuex-class';
import { calendarStoreGetter } from '@/store/calendar.store';
import SlotBookingRequest from '@/interfaces/SlotBookingRequest.interface';
import { shipArrivalStoreGetter } from '@/store/shipArrival.store';
import ShipArrival from '@/models/ShipArrival.model';
import { Booking } from '@/models/Booking.model';
import { slotStoreMutations } from '@/store/slot.store';

const CalendarStore = namespace('calendar');
const ShipArrivalStore = namespace('shipArrival');
const SlotStore = namespace('slot');

@Component({})
export default class CalendarItemRestrictionComponent extends Vue {

  @Prop()
  private slotGroup!: SlotGroup;

  @Prop()
  private shiftConfig!: ShiftConfig;

  @Prop()
  private slotType!: SlotType;

  @ShipArrivalStore.Getter(shipArrivalStoreGetter.CALENDAR_SHIP_ARRIVAL)
  private _calendarShipArrival!: undefined | ShipArrival;

  public get calendarShipArrival(): undefined | ShipArrival {
    return this._calendarShipArrival;
  }

  @CalendarStore.Getter(calendarStoreGetter.SELECTION_INFORMATION)
  private _selectionInformation!: undefined | Partial<SlotBookingRequest>;

  public get selectionInformation(): undefined | Partial<SlotBookingRequest> {
    return this._selectionInformation;
  }

  @SlotStore.Mutation(slotStoreMutations.SET_SLOTTING_ERROR)
  private setSlottingError!: (payload: { key: string, value: boolean }) => void;

  @SlotStore.Mutation(slotStoreMutations.CLEAR_SLOTTING_ERROR)
  private clearSlottingError!: () => void;

  public availabilityState: {
    isActive: boolean,
    error: boolean,
    exceeded: boolean,
    slots: number
  } = {
    isActive: false,
    error: false,
    exceeded: false,
    slots: -1
  };

  public availableSlots: number | null = null;
  public SlotType = SlotType;

  public mounted() {
    this.determineAvailableSlots();
  }

  @Watch('calendarShipArrival')
  private onCalendarSipArrivalChange() {
    this.determineAvailableSlots();
  }

  @Watch('selectionInformation')
  private onSelectionInformationChange() {
    // Check if selectionInformation change regards this slot group
    if (this.selectionInformation &&
        this.slotGroup.date === this.selectionInformation.date &&
        this.slotGroup.shift === this.selectionInformation.shift) {
      this.determineAvailableSlots();
    } else if (!this.selectionInformation) {
      this.determineAvailableSlots();
      this.clearSlottingError();
      this.availabilityState = {
        ...this.availabilityState,
        error: false,
        exceeded: false,
      }
    }
  }

  /**
   * Determines the available slots for this shift.
   * ATTENTION: MONSTER COMPLEX FUNCTION!!! BOOM BOOM PAW!
   * @private
   */
  private determineAvailableSlots(): void {
    const shiftConfigItem = this.shiftConfig[this.slotGroup.shiftLength!]!;
    const carConstraints = shiftConfigItem.map(item => item.CAR.max);
    const heavyConstraints = shiftConfigItem.map(item => item.HEAVY.max).reverse();

    let occupiedCarSlots = 0;
    let occupiedHeavySlots = 0;

    // Consider the slots which are already booked if particular ship arrival were selected
    if (this.calendarShipArrival) {
      occupiedCarSlots = this.slotGroup.bookedSlots.cars
          .filter(item => item.booking)
          .filter(item => ((item.booking as Booking).shipArrival as ShipArrival).id === this.calendarShipArrival!.id)
          .reduce((acc, curr) => acc += curr.amount!, 0);
      occupiedHeavySlots = this.slotGroup.bookedSlots.heavy
          .filter(item => item.booking)
          .filter(item => ((item.booking as Booking).shipArrival as ShipArrival)!.id === this.calendarShipArrival!.id)
          .reduce((acc, curr) => acc += curr.amount!, 0);
    }
    // Consider slots from selection
    if (this.selectionInformation &&
        this.slotGroup.date === this.selectionInformation.date &&
        this.slotGroup.shift === this.selectionInformation.shift) {
      this.availabilityState.isActive = true;
      if (this.selectionInformation.slotType === SlotType.CAR) {
        occupiedCarSlots += this.selectionInformation.amount!;
      } else {
        occupiedHeavySlots += this.selectionInformation.amount!;
      }
    } else {
      this.availabilityState.isActive = false;
      this.availabilityState.error = false;
      this.availabilityState.exceeded = false;
    }

    // Find suitable shift config
    let restrictionIndex;
    if (this.slotType === SlotType.CAR) {
      restrictionIndex = heavyConstraints?.findIndex(heavyConstraint => heavyConstraint >= occupiedHeavySlots);
    } else {
      restrictionIndex = carConstraints?.findIndex(carConstraint => carConstraint >= occupiedCarSlots);
    }

    // Determine available slots
    if (restrictionIndex < 0) {
      // Take max value if no restriction index were found
      if (this.slotType === SlotType.CAR) {
        this.availabilityState.slots = carConstraints[carConstraints.length - 1] - occupiedCarSlots;
      } else {
        this.availabilityState.slots = heavyConstraints[heavyConstraints.length - 1] - occupiedHeavySlots;
      }
    } else {
      if (this.slotType === SlotType.CAR) {
        this.availabilityState.slots = carConstraints.reverse()[restrictionIndex] - occupiedCarSlots;
      } else {
        this.availabilityState.slots = heavyConstraints.reverse()[restrictionIndex] - occupiedHeavySlots;
      }
    }

    // Update the slots  if necessary
    if (this.selectionInformation &&
        this.slotGroup.date === this.selectionInformation.date &&
        this.slotGroup.shift === this.selectionInformation.shift) {

      // ATTENTION: the constraints arrays are reversed!!
      const maxCarAmount = carConstraints[restrictionIndex];
      const maxHeavyAmount = heavyConstraints[restrictionIndex];

      this.availabilityState.exceeded = this.availabilityState.slots < 0;

      if (this.slotType === SlotType.CAR) {
        // Heavy Branch
        if (this.selectionInformation.slotType === SlotType.HEAVY && (occupiedHeavySlots > maxHeavyAmount || restrictionIndex < 0)) {
          this.availabilityState.error = true;
        } else {
          this.availabilityState.error = false;
        }
      } else {
        // Car Branch
        if (this.selectionInformation.slotType === SlotType.CAR && (occupiedCarSlots > maxCarAmount || restrictionIndex < 0)) {
          this.availabilityState.error = true;
        } else {
          this.availabilityState.error = false;
        }
      }
      // Set store error
      this.setSlottingError({
        key: `${this.slotGroup.date}-${this.slotGroup.shift}`,
        value: this.availabilityState.error || this.availabilityState.exceeded
      });
    }
  }
}
