var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!(_vm.slotType === _vm.SlotType.HEAVY && (_vm.availabilityState.error || _vm.availabilityState.exceeded)))?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref ){
var on = ref.on;
return [_c('div',{staticClass:"restriction-item d-flex"},[_c('div',_vm._g({staticClass:"calendar-item d-flex flex-column justify-center align-center",class:{
                      'has-constraint-error' : _vm.availabilityState.error || _vm.availabilityState.exceeded,
                  }},on),[(!_vm.availabilityState.error && !_vm.availabilityState.exceeded)?_c('span',{class:{
                                'font-weight-black text-subtitle-1': _vm.availabilityState.isActive,
                                'error--text': _vm.availabilityState.exceeded,
                              }},[_vm._v(" "+_vm._s(_vm.availabilityState.slots)+" ")]):_vm._e(),(_vm.availabilityState.error || _vm.availabilityState.exceeded)?_c('v-icon',{staticClass:"error-indicator",attrs:{"size":"20","color":"error"}},[_vm._v(" fas fa-exclamation-triangle ")]):_vm._e()],1),_c('div',{staticClass:"scrollbar-cover"})])]}}],null,false,2532132437)},[_c('span',[_vm._v(_vm._s(_vm.$t('CALENDAR.SLOTTING_ERROR')))])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }